import React from 'react'
import styled from 'styled-components'
import t from '../../translation'
import Banner from '../components/Banner'
import Section from '../components/Section'
import { DualText, Texts, BannerImage } from './styles'

const Section4 = props => {
  return (
    <Section background='white'>
      <Banner style={{ paddingTop: 100 }}>
        <MainImage4 src='/images/homepage/main-4.png' />
        <Texts align='right'>
          <DualText>
            <div>{t`s4.title.1`}</div>
            <div>{t`s4.title.2`}</div>
          </DualText>
          <p>{t`s4.p.1`}</p>
          <p style={{ fontSize: 14 }}>{t`s4.p.2`}</p>
        </Texts>
      </Banner>
    </Section>
  )
}

const MainImage4 = styled(BannerImage)`
  width: 380px;
`

export default Section4
