import React from 'react'
import styled from 'styled-components'
import t from '../../translation'
import Banner from '../components/Banner'
import Section from '../components/Section'
import { DualText, Texts, BannerImage } from './styles'

const Section3 = props => {
  return (
    <Section background='white'>
      <Banner>
        <Texts align='left'>
          <DualText3 align='left'>
            <div>{t`s3.title.1`}</div>
            <div>{t`s3.title.2`}</div>
          </DualText3>
          <p>{t`s3.p.1`}</p>
        </Texts>
        <MainImage3 src='/images/homepage/main-3.png' />
      </Banner>
    </Section>
  )
}

const MainImage3 = styled(BannerImage)`
  height: 380px;
  transform: scale(1.2);
  right: -20px;
`

const DualText3 = styled(DualText)`
  > *:nth-child(1) {
    font-size: 36px;
  }

  > *:nth-child(2) {
    font-size: 28px;
  }
`

export default Section3
