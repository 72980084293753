import React, { useState } from 'react'
import styled from 'styled-components'
import t from '../translation'

const SideMenu = props => {
  const [open, setOpen] = useState(false)

  const handleClickItem = e => {
    setOpen(false)
  }

  return (
    <>
      <MenuBtn src='/images/menu.svg' onClick={() => setOpen(true)} />
      <Container open={open} onClick={() => setOpen(false)}>
        <InnerContainer onClick={e => e.stopPropagation()}>
          <MenuBtn
            style={{ marginBottom: 50, alignSelf: 'flex-end' }}
            src='/images/menu-close.svg'
            onClick={() => setOpen(false)}
          />
          <HeaderItem onClick={handleClickItem} href={t`header.m.item.1.href`}>{t`header.m.item.1`}</HeaderItem>
          <HeaderItem onClick={handleClickItem} href={t`header.m.item.2.href`}>{t`header.m.item.2`}</HeaderItem>
          <HeaderItem onClick={handleClickItem} href={t`header.m.item.3.href`}>{t`header.m.item.3`}</HeaderItem>
          <HeaderItem onClick={handleClickItem} href={t`header.m.item.4.href`}>{t`header.m.item.4`}</HeaderItem>
          <HeaderItem onClick={handleClickItem} href={t`header.m.item.5.href`}>{t`header.m.item.5`}</HeaderItem>
          <HeaderItem onClick={handleClickItem} href={t`header.m.item.6.href`}>{t`header.m.item.6`}</HeaderItem>
          <HeaderItem onClick={handleClickItem} href={t`header.m.item.7.href`}>{t`header.m.item.7`}</HeaderItem>
          <Filler />
          <Copyright>{t`footer.copyright`}</Copyright>
        </InnerContainer>
      </Container>
    </>
  )
}

const MenuBtn = styled.img`
  height: 50px;
  width: 50px;
  cursor: pointer;
`

const Container = styled.div`
  position: fixed;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: -100%;
  background: transparent;
  display: flex;
  flex-direction: row-reverse;
  transition: right 0.3s;

  ${props => props.open ? `
    right: 0;
    background: rgba(0,0,0,0.2);
  ` : ''}
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  max-width: 400px;
  background: white;
  padding: 20px 20px 30px 30px;
  transition: transform 0.3s;
  box-sizing: border-box;
`

const HeaderItem = styled.a`
  color: inherit;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 0px;
  margin-bottom: 10px;
`

const Copyright = styled.div`
  font-size: 12px;
  text-align: center;
`

const Filler = styled.div`
  flex: 1;
`

export default SideMenu
