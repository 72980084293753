import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import Modal from './Modal'

const VideoPlayer = (props) => {
  const youtubePlayer = useRef(null)
  const videoPlayer = useRef(null)

  const handleClose = () => {
    if (youtubePlayer.current) {
      youtubePlayer.current.pauseVideo()
      youtubePlayer.current.seekTo(0, true)
    }
    if (videoPlayer.current) {
      videoPlayer.current.pause()
      videoPlayer.current.currentTime = 0
    }
    props.onClose()
  }

  return (
    <Modal
      visible={props.visible}
      onClose={handleClose}
    >
      <video
        ref={ref => {
          videoPlayer.current = ref
        }}
        width='100%'
        controls
      >
        <source src={props.videoLink} type='video/mp4' />
        Your browser does not support HTML video.
      </video>
    </Modal>
  )
}

export const playVideo = (props) => {
  const mountTarget = document.createElement('div')
  document.body.appendChild(mountTarget)
  const onClose = () => {
    ReactDOM.unmountComponentAtNode(mountTarget)
    mountTarget.remove()
  }
  ReactDOM.render(
    <VideoPlayer
      videoLink={props.videoLink}
      onClose={onClose}
      visible
    />,
    mountTarget
  )
}

export default VideoPlayer
