import React from 'react'
import styled from 'styled-components'
import t from '../../translation'
import Section from '../components/Section'

const InnovateUKBanner = props => {
  return (
    <Section>
      <Container>
        <span>{t`homepage.support.text`}</span>
        <img alt='' src='/images/homepage/innovate-uk.png' />
      </Container>
    </Section>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  text-align: center;
  flex-wrap: wrap;
  padding: 10px 0px;

  > * {
    margin: 15px;
  }

  img {
    height: 80px;
  }
`

export default InnovateUKBanner
