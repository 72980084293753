import React, { useState } from 'react'
import styled from 'styled-components'
import t from '../translation'
import Section from './components/Section'
import Modal from './components/Modal'
import ContactUsCard from './components/ContactUsCard'
import Link from './components/Link'

const Footer = props => {
  const [contactUsVisible, setContactUsVisible] = useState(false)
  return (
    <>
      <Section>
        <Container>
          <Nav>
            <Link to={t`footer.item.1.link`} onClick={() => setContactUsVisible(true)}>{t`footer.item.1.name`}</Link>
            <Link to={t`footer.item.2.link`}>{t`footer.item.2.name`}</Link>
            <Link to={t`footer.item.3.link`}>{t`footer.item.3.name`}</Link>
            <Link to={t`footer.item.4.link`}>{t`footer.item.4.name`}</Link>
            <Link to={t`footer.item.5.link`}>{t`footer.item.5.name`}</Link>
            <Link to={t`footer.item.6.link`}>{t`footer.item.6.name`}</Link>
            <Link to={t`footer.item.7.link`}>{t`footer.item.7.name`}</Link>
          </Nav>
          <Address>{t`contact.address`}</Address>
          <Tel>{t`contact.communication`}</Tel>
          <GoalsContainer>
            {t`footer.text.1`}
            <GoalsContainer>
              <img alt='' src='https://www.solarfuerkinder.de/cdn/images/footer/goal-04.png' />
              <img alt='' src='https://www.solarfuerkinder.de/cdn/images/footer/goal-07.png' />
              <img alt='' src='https://www.solarfuerkinder.de/cdn/images/footer/goal-11.png' />
              <img alt='' src='https://www.solarfuerkinder.de/cdn/images/footer/goal-13.png' />
            </GoalsContainer>
          </GoalsContainer>
          <SmLinks>
            <a rel='noopener noreferrer' target='_blank' href={t`sm.fb`}>
              <img alt='' src='/images/social/fb.svg' />
            </a>
            <a rel='noopener noreferrer' target='_blank' href={t`sm.twitter`}>
              <img alt='' src='/images/social/twitter.svg' />
            </a>
            <a rel='noopener noreferrer' target='_blank' href={t`sm.insta`}>
              <img alt='' src='/images/social/insta.svg' />
            </a>
            <a rel='noopener noreferrer' target='_blank' href={t`sm.linkedin`}>
              <img alt='' src='/images/social/linkedin.svg' />
            </a>
          </SmLinks>
          <Copyright>{t`footer.copyright`}</Copyright>
          <Code>{t`footer.code`}</Code>
        </Container>
      </Section>
      <Modal visible={contactUsVisible} onClose={() => setContactUsVisible(false)}>
        <ContactUsCard />
      </Modal>
    </>
  )
}

const Container = styled.footer`
  background-color: white;
  color: var(--text-dark);
  padding: 50px 0px;
  display: grid;
  grid-gap: 2em;
  grid-template-areas:
    "nav add tel goals goals"
    "sm copy copy copy code";
  font-weight: 700;

  > * {
    justify-self: center;
  }

  a {
    color: inherit;
    text-decoration: none;
    display: block;
  }

  @media(max-width: 1200px) {
    grid-template-areas:
      "nav add tel"
      "goals goals goals"
      "sm copy code"
  }

  @media(max-width: 767px) {
    padding: 10px;
    padding-bottom: 50px;
    grid-gap: 2em 1em;
    grid-template-areas:
      "nav nav nav"
      "add add add"
      "tel tel tel"
      "goals goals goals"
      "sm sm sm"
      "code code code"
      "copy copy copy";
    > * {
      justify-self: stretch;
    }
  }
`

const Nav = styled.nav`
  grid-area: nav;
`

const Address = styled.div`
  grid-area: add;
  white-space: break-spaces;
`

const Tel = styled.div`
  grid-area: tel;
  white-space: break-spaces;
`

const MobileCentered = styled.div`
  @media(max-width: 767px) {
    text-align: center;
  }
`

const GoalsContainer = styled(MobileCentered)`
  grid-area: goals;
  text-align: center;
  max-width: 580px;
  font-size: 14px;
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    justify-content: space-between;
    > * {
      height: 100px;
      width: 100px;
      margin: 0 10px 10px 0;
    }
  }
`

const SmLinks = styled.div`
  grid-area: sm;
  display: flex;
  max-width: 340px;
  img {
    width: 25px;
    height: 25px;
    margin-right: 20px;
  }

  @media(max-width: 767px) {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`

const Copyright = styled(MobileCentered)`
  grid-area: copy;
  text-align: center;
`

const Code = styled(MobileCentered)`
  grid-area: code;
`

export default Footer
