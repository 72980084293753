import React from 'react'
import styled from 'styled-components'

const Section = props => {
  return (
    <Container {...props} background={props.background}>
      <InnerContainer>
        {props.children}
      </InnerContainer>
    </Container>
  )
}

const Container = styled.section`
  background: ${props => props.background};
`

const InnerContainer = styled.div`
  width: 1300px;
  max-width: 90vw;
  margin: 0 auto;
`

export default Section
