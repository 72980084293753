import { TEAMS_WEBHOOK_URL } from '../config'

const fetch = window.fetch

const domain = window.location.href.includes('localhost')
  ? 'http://alpha.solarforschools.co.uk:8081'
  : 'https://www2.solarforschools.co.uk'

const coreHost = window.location.href.includes('localhost')
  ? 'http://localhost:5001'
  : 'https://coreapi.solarforschools.co'

export const subscribeForTesting = (userEmail) => {
  return fetch(TEAMS_WEBHOOK_URL, {
    method: 'POST',
    mode: 'no-cors',
    json: true,
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      '@type': 'MessageCard',
      title: `New mobile app test request from ${userEmail}`,
      text: `Email Address: ${userEmail}`
    })
  })
}

export const appTestRequest = email => {
  return fetch(`${domain}/api/app-test-request`, {
    method: 'POST',
    mode: 'no-cors',
    json: true,
    body: JSON.stringify({ email }),
    headers: { 'Content-type': 'application/json' }
  })
}

export const getOnlineSafetyHtml = async () => {
  return require('./onlineSaftey.json').html
}

export const searchSchools = (term) => {
  const url = `${domain}/api/search/schools/autocomplete`
  return fetch(url, {
    method: 'post',
    mode: 'cors',
    body: JSON.stringify({ term, region: 'ALL' }),
    headers: { 'Content-Type': 'application/json' }
  })
    .then(res => res.json())
    .then(res => res.schoolsList)
    .catch(e => [])
}

export const requestPremiumAccess = details => {
  const url = 'https://solarforschools.webhook.office.com/webhookb2/51eeab2d-1e88-4465-8dd9-7cbf60a28441@1aa578e2-4d37-46d2-a53a-6621d297f5f1/IncomingWebhook/cc36a1f450c7445586497117e22e425c/ae598453-e925-48b6-81ff-4667a238d60e'
  return fetch(url, {
    method: 'post',
    mode: 'no-cors',
    json: true,
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      '@context': 'https://schema.org/extensions',
      '@type': 'MessageCard',
      themeColor: '0072C6',
      title: 'Premium Access Request',
      text: `Name: ${details.name}\n\nEmail: ${details.email}\n\nCountry: ${details.country}\n\nSchool: ${details.school}\n\nPostcode: ${details.postcode}\n\nSlug: ${details.slug}`
    })
  })
}

export const registerForPremium = details => {
  const payload = {
    name: details.name,
    email: details.email,
    country: details.country,
    school: details.slug,
    zipcode: details.postcode,
    source: 'Premium Access'
  }
  return fetch(`${coreHost}/users/solar-registrations`, {
    method: 'post',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' }
  }).then(res => res.json())
}

export const getFileUrl = path => `${domain}/get-file?path=${path}`
