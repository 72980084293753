import React from "react";
import styled from "styled-components";
import t from "../translation";
import SideMenu from "./SideMenu";
import Section from "./components/Section";
import Link from "./components/Link";

const Header = () => {
  return (
    <>
      <Section>
        <DesktopHeader>
          <Link to="/">
            <Logo src="/images/logo.svg" />
          </Link>
          <div>
            <HeaderLink href={t`links.app-store`}>For iOS</HeaderLink>
            <HeaderLink href={t`links.play-store`}>For Android</HeaderLink>
            <HeaderLink href="https://webapp.solarforschools.co.uk">
              For Web App
            </HeaderLink>
            <HeaderLink href="/#premium">Premium Access</HeaderLink>
            <HeaderLink href="https://www.solarforschools.co.uk/">
              Solar for Schools
            </HeaderLink>
          </div>
        </DesktopHeader>
      </Section>
      <MobileHeader>
        <Link to="/">
          <Logo src="/images/logo.svg" />
        </Link>
        <SideMenu />
      </MobileHeader>
    </>
  );
};

const Logo = styled.img`
  height: 50px;
  cursor: pointer;
  box-shadow: none !important;
`;

export const HeaderLink = styled.a`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  color: var(--navy-color);
  cursor: pointer;
  white-space: nowrap;
  box-shadow: none !important;
`;

export const DesktopHeader = styled.header`
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 20px;

  > div {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    > * {
      margin: 0px 20px;
    }
  }

  @media (max-width: 1060px) {
    display: none;
  }
`;

const MobileHeader = styled.header`
  justify-content: space-between;
  padding: 20px;
  display: none;

  @media (max-width: 767px) {
    padding: 20px;
  }

  @media (max-width: 1024px) {
    padding: 20px 50px;
  }

  @media (max-width: 1060px) {
    display: flex;
  }
`;

export default Header;
