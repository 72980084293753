import React, { useCallback, useRef, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  requestPremiumAccess,
  searchSchools,
  registerForPremium,
} from "../../data/client";
import t from "../../translation";
import { DualText, Texts, BannerImage } from "./styles";

const AccessForm = (props) => {
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [slug, setSlug] = useState("");
  const schoolRef = useRef();
  const postcodeRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = getFormData(e.target);
    setLoading(true);
    Promise.all([registerForPremium(data), requestPremiumAccess(data)])
      .then(() => {
        setLoading(false);
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 5000);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  const getFormData = (form) => {
    const data = {};
    for (const field of form) {
      if (!field.name) continue;
      if (field.type === "checkbox") {
        data[field.name] = field.checked;
      } else {
        data[field.name] = field.value;
      }
    }
    return data;
  };

  const handleSchoolNameChange = useCallback(
    _.debounce((e) => {
      const term = e.target.value;
      if (!term || term.length < 3) {
        return;
      }
      searchSchools(term).then((suggestions) => {
        setSuggestions(suggestions);
        setOpen(!!suggestions.length);
        const close = () => {
          setOpen(false);
          document.removeEventListener("click", close);
        };
        document.addEventListener("click", close);
      });
    }, 400),
    []
  );

  return (
    <Root>
      <MainImage2 src="/images/homepage/registration-form-placeholder.png" />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  // height: 400px;
  // width: 450px;
  top: 25px;
`;

const Container = styled.form`
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
  padding: 25px;
  border-radius: 10px;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 95vw;
  box-sizing: border-box;
  z-index: 2;
`;

const Background = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 120%;
  height: 120%;
  max-width: 99vw;
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  *:nth-child(1) {
    width: 55%;
    margin-right: 10px;
  }

  *:nth-child(2) {
    width: 35%;
  }
`;

const Input = styled.input`
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 15px 10px;
  background: white;
  font-size: 18px;
  color: var(--text-dark);
`;

const Button = styled.button`
  width: 100%;
  border-radius: 10px;
  padding: 15px 0px;
  text-align: center;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 24px;
  background: var(--yellow);
  cursor: pointer;
  color: var(--text-dark);
  position: relative;
`;

const SuggestionsContainer = styled.div`
  width: 100%;
  position: relative;
`;

const Suggestions = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-sizing: border-box;
  border-radius: 10px;
  height: 0px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: height 0.2s;
  z-index: 2;

  ${(props) =>
    props.open
      ? `
    height: 200px;
    overflow: auto;
    box-shadow: 0 0 10px 3px rgb(0 0 0 / 10%);
  `
      : ""}

  > div {
    padding: 10px;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;

    :hover {
      background: #efefef;
    }
  }
`;

const SuccessMsg = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: white;
  font-size: 18px;
  font-weight: normal;
  width: 100%;
`;
const MainImage2 = styled(BannerImage)`
  width: 300px;
  transform: scale(1.2);
  top: -50px;
`;
export default AccessForm;
