import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'

const Modal = ({
  visible,
  children,
  dismissable = true,
  onClose,
  backgroundColor,
  disableScroll = false
}) => {
  const dismissCallback = useCallback((event) => {
    if (event.keyCode === 27 || event.key === 'Escape') { // esc key
      onClose()
    }
  }, [])

  const style = backgroundColor ? { background: backgroundColor } : null

  useEffect(() => {
    if (visible) {
      if (dismissable) { window.addEventListener('keydown', dismissCallback) }
      // if (disableScroll) { toggleScroll(false) }
    } else {
      if (dismissable) { window.removeEventListener('keydown', dismissCallback) }
      // if (disableScroll) { toggleScroll(true) }
    }
  }, [visible])

  if (!visible) return null

  return (
    <ModalWrapper
      onClick={dismissable ? onClose : null}
      style={style}
    >
      <div onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.2);
  transition: opacity 0.2s ease-in;
  z-index: 100;
`

export default Modal
