import React from 'react'
import styled from 'styled-components'

const PremiumBadge = props => {
  return (
    <Badge
      position={props.position}
      src='/images/homepage/premium-badge.svg'
    />
  )
}

const Badge = styled.img`
  position: absolute;
  top: -10px;
  ${props => props.position === 'top-right' ? `
    right: -20px;
  ` : `
    left: -20px;
  `}
`

export default PremiumBadge
