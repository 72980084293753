import React, { useRef, useState } from 'react'
import styled from 'styled-components'

const Collapsible = ({
  children,
  title,
  containerStyle,
  triggerId
}) => {
  const [active, setActive] = useState(false)
  const contentRef = useRef()
  const handleClickTrigger = () => {
    contentRef.current.style.maxHeight = active
      ? 0
      : contentRef.current.scrollHeight + 'px'
    setActive(!active)
  }
  return (
    <div style={containerStyle}>
      <Trigger active={active} onClick={handleClickTrigger} id={triggerId}>
        <Title>{title}</Title>
        <img
          alt=''
          className='mw-collapsible-indicator'
          src='/images/icons/chevron-down.svg'
        />
      </Trigger>
      <Content
        ref={ref => (contentRef.current = ref)}
        active={active}
      >
        {children}
      </Content>
    </div>
  )
}

const Trigger = styled.div`
  display: flex;
  font-weight: 600;
  padding: 30px 0px;
  align-items: center;
  cursor: pointer;

  & img {
    margin-left: 24px;
    height: 30px;
    width: 30px;
    transition: 0.2s;
    transform: ${props => props.active ? 'rotate(180deg)' : 'none'};
  }

  @media(max-width: 600px) {
    padding: 20px;
    justify-content: space-evenly;
  }
`

const Title = styled.div`
  font-size: 36px;
  font-weight: 400;
`

const Content = styled.div`
  max-height: 0;
  transition: max-height 0.4s ease-out;
  overflow: ${props => props.active ? 'visible' : 'hidden'};
`

export default Collapsible
