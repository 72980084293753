import React from 'react'
import styled from 'styled-components'
import t from '../../../translation'
import Section from '../../components/Section'
import { DualText } from '../styles'
import WhatWeOfferItem from './WhatWeOfferItem'

const WhatWeOfferSection = props => {
  return (
    <Section background='white' style={{ padding: '30px 0px' }}>
      <DualText align='center'>
        <div>{t`s.wwo.title.1`}</div>
      </DualText>
      <Wrap>
        <WhatWeOfferItem
          href='#download'
          title={t`s.wwo.item.1.title`}
          content={t`s.wwo.item.1.text.1`}
          footer={t`s.wwo.item.1.text.2`}
          background='var(--green)'
        />
        <WhatWeOfferItem
          href='/#premium'
          title={t`s.wwo.item.2.title`}
          content={t`s.wwo.item.2.text.1`}
          footer={t`s.wwo.item.2.text.2`}
          background='linear-gradient(300deg, #FFBF00 30%, #FFCB30 30%, #FFCB30 40%, #FFBF00 40%, #FFBF00, #FFBF00 45%, #FFCB30 45%, #FFCB30 65%, #FFBF00 65%, #FFBF00);'
        />
        <WhatWeOfferItem
          stars
          href={t`links.premium-page`}
          title={t`s.wwo.item.3.title`}
          content={(
            <>
              {t`s.wwo.item.3.text.1`}
              <br /><br />
              <span>
                {t`s.wwo.item.3.text.3`}
                <span>
                  <a href={t`links.premium-see-more`}>{t`s.wwo.item.3.link.1`}</a>
                </span>
              </span>
            </>
          )}
          footer={t`s.wwo.item.3.text.2`}
          background='linear-gradient(300deg, #117894 30%, #0088AD 30%, #0088AD 40%, #117894 40%, #117894, #117894 45%, #0088AD 45%, #0088AD 65%, #117894 65%, #117894);'
        />
      </Wrap>
    </Section>
  )
}

const Wrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 20px;
  > * {
    margin: 20px;
  }
`

export default WhatWeOfferSection
