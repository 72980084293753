import React from 'react'
import styled from 'styled-components'
import t from '../../translation'
import Banner from '../components/Banner'
import Section from '../components/Section'
import { DualText, Texts, BannerImage } from './styles'

const Section2 = props => {
  return (
    <Section background='var(--light-grey)' style={{ paddingTop: 30 }}>
      <Banner>
        <MainImage2 src='/images/homepage/main-2.png' />
        <Texts align='right'>
          <DualText>
            <div>{t`s2.title.1`}</div>
            <div>{t`s2.title.2`}</div>
          </DualText>
          <p>{t`s2.p.1`}</p>
        </Texts>
      </Banner>
    </Section>
  )
}

const MainImage2 = styled(BannerImage)`
  width: 300px;
  transform: scale(1.2);
  top: -50px;
`

export default Section2
