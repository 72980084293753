import React from 'react'
import styled from 'styled-components'
import t from '../../translation'
import Section from '../components/Section'
import { DualText } from './styles'

const TestimonySection = props => {
  return (
    <Section background='var(--light-grey)' style={{ padding: '30px 0px' }}>
      <DualText align='center'>
        <div>{t`s.testimony.title.1`}</div>
      </DualText>
      <Wrap>
        <TestimonyItem>
          <div>{t`s.testimony.item.1.text`}</div>
          <div>{t`s.testimony.item.1.role`}</div>
        </TestimonyItem>
        <TestimonyItem>
          <div>{t`s.testimony.item.2.text`}</div>
          <div>{t`s.testimony.item.2.role`}</div>
        </TestimonyItem>
        <TestimonyItem>
          <div>{t`s.testimony.item.3.text`}</div>
          <div>{t`s.testimony.item.3.role`}</div>
        </TestimonyItem>
      </Wrap>
    </Section>
  )
}

const TestimonyItem = styled.div`
  width: 400px;
  height: auto;
  padding: 25px;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > * {
    white-space: break-spaces;
  }

  *:nth-child(1) {
    font-weight: 600;
    text-align: left;
    flex: 1;
    margin-bottom: 10px;
  }

  *:nth-child(2), *:nth-child(3) {
    text-align: right;
  }

  @media(max-width: 767px) {
    height: auto;
  }
`

const Wrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 20px;
  > * {
    margin: 6px;
  }
`

export default TestimonySection
