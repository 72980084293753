import React from 'react'
import styled from 'styled-components'
import t from '../../translation'

const ContactUsCard = ({
  children,
  title
}) => {
  return (
    <Container>
      <Title>Contact Us</Title>
      <Responsive>
        <Column>
          <div>Want to chat? Send us an email by clicking the mail icon, or mail us directly at</div>
          <HeaderLink href={`mailto:${t`contact.email`}`}>
            {t`contact.email`}
          </HeaderLink>
          <div>We can't wait to hear from you!</div>
        </Column>
        <a href={`mailto:${t`contact.email`}`}>
          <CardImage alt='' src='images/chat.png' />
        </a>
      </Responsive>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  max-width: min(80vw, 700px);
  box-sizing: border-box;

  @media(max-width: 600px) {
    padding: 24px;
  }
`

const Title = styled.div`
  font-size: 52px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;

  @media(max-width: 600px) {
    font-size: 40px;
  }
`

export const Responsive = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media(max-width: 600px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  
  & > * {
    margin-bottom: 36px;
  }

  @media(max-width: 600px) {
    & > * {
      margin-bottom: 16px;
    }
  }
`

export const HeaderLink = styled.a`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  color: var(--navy-color);
  cursor: pointer;
  box-shadow: none !important;
`

const CardImage = styled.img`
  width: 300px;
  max-width: 80%;
`

export default ContactUsCard
