import React from 'react'
import styled from 'styled-components'
import Section from '../components/Section'
import { DualText } from './styles'
import PremiumBadge from './PremiumBadge'
import t from '../../translation'

const TopicsSection = props => {
  return (
    <Section background='var(--light-grey)' style={{ paddingTop: 20 }}>
      <DualText align='center'>
        <div>{t`s.topic.title.1`}</div>
        <div>{t`s.topic.title.2`}</div>
      </DualText>
      <Wrap>
        <ImageCard>
          <Image src='/images/homepage/topic-1.png' alt='' />
          {t`s.topic.item.1.title`}
          <PremiumBadge position='top-right' />
        </ImageCard>
        <ImageCard>
          <Image src='/images/homepage/topic-2.png' alt='' />
          {t`s.topic.item.2.title`}
          <PremiumBadge position='top-right' />
        </ImageCard>
        <ImageCard>
          <Image src='/images/homepage/topic-3.png' alt='' />
          {t`s.topic.item.3.title`}
          <PremiumBadge position='top-right' />
        </ImageCard>
        <ImageCard>
          <Image src='/images/homepage/topic-4.png' alt='' />
          {t`s.topic.item.4.title`}
          <PremiumBadge position='top-right' />
        </ImageCard>
      </Wrap>
    </Section>
  )
}

const ImageCard = styled.div`
  width: 260px;
  height: 260px;
  border-radius: 10px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
  margin: 25px 25px 5px 5px;
  position: relative;
`

const Image = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin-bottom: 15px;
`

const Wrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  position: relative;
  top: 50px;
`

export default TopicsSection
