import React from 'react'
import styled from 'styled-components'

const WhatWeOfferItem = props => {
  return (
    <Container href={props.href}>
      <Header background={props.background}>
        {props.title}
        {props.stars && <Stars src='/images/homepage/stars-large.png' />}
      </Header>
      <Content>{props.content}</Content>
      <FloatingFooter background={props.background}>
        {props.footer}
        {props.stars && <Stars src='/images/homepage/stars-small.png' />}
      </FloatingFooter>
    </Container>
  )
}

const Container = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  position: relative;
  height: 380px;
  width: 360px;
  margin: 10px 20px 20px 10px;
  color: white;
  background: #F4F4F4;
  max-width: 400px;
  padding-bottom: 15px;

  a {
    color: inherit;
    font-weight: 600;
  }

  @media(max-width: 767px) {
    width: 90vw;
    height: auto;
  }
`

const Header = styled.header`
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background: ${props => props.background};
  text-align: center;
  padding: 12px 0px;
  font-size: 24px;
  font-weight: 600;
  position: relative;
`

const Content = styled.p`
  white-space: break-spaces;
  color: var(--text-dark);
  padding: 30px 18px;
`

const FloatingFooter = styled.a`
  width: 200px;
  padding: 20px 0px;
  border-radius: 25px;
  border-bottom-left-radius: 2px;
  background: ${props => props.background};
  position: absolute;
  bottom: -10px;
  right: -10px;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  text-decoration: none;
`

const Stars = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: contain;
`

export default WhatWeOfferItem
