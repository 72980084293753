import React from 'react'
import {
  BrowserRouter as Router, Route, Switch
} from 'react-router-dom'
import { getFileUrl } from '../data/client'
import useScript from '../hooks/useScript'
import Footer from './Footer'
import Header from './Header'
import Homepage from './homepage'
import ImprintScreen from './ImprintScreen'
import OnlineSafety from './OnlineSaftey'
import OurData from './OurData'
import ScrollToTop from './components/ScrollToTop'

const App = () => {
  const scriptStatus = useScript(getFileUrl('/appwebsite/translations/gb-en.js'))
  if (scriptStatus !== 'ready') {
    return <div />
  }
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Header />
        <Switch>
          <Route path='/imprint'>
            <ImprintScreen />
          </Route>
          <Route path='/online-safety'>
            <OnlineSafety />
          </Route>
          <Route path='/our-data'>
            <OurData />
          </Route>
          <Route path='/'>
            <Homepage />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  )
}

export default App
