import React from 'react'
import styled from 'styled-components'
import t from '../../translation'
import Banner from '../components/Banner'
import Section from '../components/Section'
import AccessForm from './AccessForm'
import { DualText, Texts } from './styles'

const Section5 = props => {
  return (
    <Section background='white' id='premium'>
      <Banner5 style={{ paddingTop: 60 }}>
        <Texts align='left'>
          <DualText>
            <div>{t`s5.title.1`}</div>
            <div>{t`s5.title.2`}</div>
          </DualText>
          <p>{t`s5.p.1`}</p>
          <p>{t`s5.p.2`}</p>
          <p>{t`s5.p.3`}</p>
        </Texts>
        <AccessForm />
      </Banner5>
    </Section>
  )
}

const Banner5 = styled(Banner)`
  align-items: center;
  flex-wrap: wrap;
  > * {
    margin: 0px 10px;
  }
  p {
    margin: 20px 0px !important;
  }
  
  // access form
  > *:nth-child(2) {
    margin-top: 20px;
  }
  @media(max-width: 767px) {
    > *:nth-child(2) {
      margin: 0px;
      margin-top: 10px;
      max-width: 90vw;
    }
  }

`

export default Section5
