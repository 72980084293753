import React, { Fragment } from 'react'
import styled from 'styled-components'
import Collapsible from '../components/Collapsible'
import Section from '../components/Section'

const FaqList = props => {
  return (
    <Section background={props.background}>
      <Collapsible title={props.title} containerStyle={styles.container}>
        <Content>
          {props.items.map((item, index) => {
            return (
              <Fragment key={index}>
                <Question>{item.question}</Question>
                <Answer dangerouslySetInnerHTML={{ __html: item.answer }} />
              </Fragment>
            )
          })}
          <Image src={props.image} />
        </Content>
      </Collapsible>
    </Section>
  )
}

const Content = styled.div`
  text-align: left;
  width: 700px;
  max-width: 95vw;
  position: relative;

  @media(max-width: 600px) {
    width: 80vw;
    padding-left: 10px;
  }
`

const styles = {
  container: {
    backgroundColor: 'var(--light-blue-color)'
  }
}

const Question = styled.div`
  font-size: 30px;
  margin-bottom: 20px;
  
  @media(max-width: 767px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`

const Answer = styled.div`
  padding-bottom: 24px;
  white-space: pre-line;
  a {
    color: inherit;
    font-weight: 600;
  }
`

const Image = styled.img`
  position: absolute;
  width: 220px;
  bottom: 50px;
  right: 0px;
  transform: translateX(calc(100% + 100px));

  @media(max-width: 1199px) {
    display: none;
  }
`

export default FaqList
