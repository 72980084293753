import React from 'react'
import t from '../../translation'
import FaqList from './FaqList'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import TeachersSection from './TeachersSection'
import TestimonySection from './TestimonySection'
import TopicsSection from './TopicsSection'
import WhatWeOfferSection from './what-we-offer'
import InnovateUKBanner from './InnovateUKBanner'

const Homepage = () => {
  return (
    <main>
      <Section1 />
      <Section2 />
      <Section3 />
      <TopicsSection />
      <Section4 />
      <TestimonySection />
      <Section5 />
      <WhatWeOfferSection />
      <TeachersSection />
      <InnovateUKBanner />
      <FaqList
        title={t`faq.general.title`}
        items={t`faq.general`}
        image='/images/homepage/faq-img-full.png'
        background='#FFE8A4'
      />
      <FaqList
        title={t`faq.tech.title`}
        items={t`faq.tech`}
        image='/images/homepage/faq-img-half.png'
        background='var(--light-grey)'
      />
    </main>
  )
}

export default Homepage
