import { useEffect, useState } from 'react'
import { getOnlineSafetyHtml } from '../data/client'

const OnlineSafety = () => {
  const [content, setContent] = useState(undefined)
  useEffect(() => {
    getOnlineSafetyHtml().then(data => {
      setContent(data)
    })
    window.scrollTo(0, 0)
  }, [])

  return (
    (
      <div
        dangerouslySetInnerHTML={{
          __html: content
        }}
      />
    )
  )
}

export default OnlineSafety
