import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getFileUrl } from '../data/client'
import Section from './components/Section'

const ImprintScreen = props => {
  const [content, setContent] = useState('')

  useEffect(() => {
    const url = getFileUrl('/appwebsite/imprint.html')
    window.fetch(url)
      .then(res => res.text())
      .then(setContent)
  }, [])

  return (
    <Section>
      <Container dangerouslySetInnerHTML={{ __html: content }} />
    </Section>
  )
}

const Container = styled.div`
  font-size: 18px;
  padding: 50px;
  min-height: 90vh;

  @media(max-width: 767px) {
    padding: 10px;
  }
`

export default ImprintScreen
