import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Section from './components/Section'

const OurData = () => {
  return (
    <Section background='#BAF0F5'>
      <Conatiner>
        <InnerContainer>
          <Row style={{ marginBottom: 36 }}>
            <Link to='/'>
              <img src='/images/icons/back-chevron.png' alt='' />
            </Link>
            <Title>How do we make our calculations?</Title>
          </Row>
          <Title>Carbon savings</Title>
          <P>We calculate the carbon savings from a solar panel system by first adding up the amount of electricity that the solar panels generate. Then, we compare the carbon emissions that would be emitted if that amount of electricity came from the main electricity grid instead of from the solar panels (remember--solar panels generate zero-carbon electricity). This comparison is the amount of carbon savings that the solar panels provide.</P>
          <P>To calculate the amount of carbon emissions from the main electricity grid per unit of electricity, we look at something called the carbon emission intensity of electricity generation. This measurement differs between countries. This is due to the amount of renewable energy each country has supporting its main electricity grid. You can find the data we use <a href='https://www.eea.europa.eu/data-and-maps/daviz/co2-emission-intensity-7#tab-googlechartid_googlechartid_googlechartid_googlechartid_chart_11111_filters=%7B%22rowFilters%22%3A%7B%7D%3B%22columnFilters%22%3A%7B%22pre_config_ugeo%22%3A%5B%22European%20Union%20-%2027%20countries%20(from%202020)%22%5D%7D%7D'>here.</a></P>
          <P>Ultimately, we make a calculation that accurately reflects the impact that the solar panels have on carbon emissions reduction in the region, so it is a meaningful number to show your teachers and parents!</P>

          <Title>Carbon savings equivalents</Title>

          <P>We know that a tonne of carbon is hard to visualise; what is the impact of saving a tonne of carbon? To help answer this question, we compare carbon savings to different things; one example is the number of trees that would store the same amount of carbon.</P>

          <P>In the following links, you can learn more about these carbon savings equivalences:</P>

          <P><U href='https://www.co2meter.com/blogs/news/could-global-co2-levels-be-reduced-by-planting-trees' target='_blank'>How much carbon do hardwood trees store?</U></P>

          <P><U href='http://www.fao.org/news/story/en/item/197623/icode/' target='_blank'>How much carbon is emmitted by meat production from conventional livestock farming?</U></P>

          <Title>Electricity generation equivalents</Title>

          <P>We also know that it is hard to comprehend how much electricity can be generated by the solar panels; what can 10,000 kWh of electricity power? To help you understand the amount of electricity that solar panels can generate, we look at how much electricity powers electric vehicles. Specifically, we show you how many e-vehicles can be powered for one entire year!</P>

          <P>We use data about the amount of electricity needed to power an e-vehicle per km from <a href='https://www.virta.global/blog/ev-charging-101-how-much-electricity-does-an-electric-car-use' target='_blank'>this source</a>, and we use data about how many km a car travels in a year from <a href='https://www.odyssee-mure.eu/publications/efficiency-by-sector/transport/distance-travelled-by-car.html' target='_blank'>this dataset</a>. Did you know that the average distance travelled by a car differs depending on the country? Makes sense; England is quite a bit smaller than Germany!</P>

          <Title>Find out more</Title>

          <P>If you want to learn more about trees and the role they can play in slowing climate change, find a short report from the London Imperial College from July 2020 <a href='https://spiral.imperial.ac.uk:8443/bitstream/10044/1/80271/6/What%20role%20can%20forests%20play%20in%20tackling%20climate%20change.pdf' target='_blank'>here.</a></P>

          <P>Or if you want to learn about the importance of diet choices on slowing down climate change, read more about why local food choices may be better than vegan eating <a href='https://www.bbc.com/future/article/20200211-why-the-vegan-diet-is-not-always-green' target='_blank'>here.</a></P>
        </InnerContainer>
      </Conatiner>
    </Section>
  )
}

const Conatiner = styled.div`
  padding: 50px 0px;

  @media(max-width: 767px) {
    padding: 16px 0px;
  }

  a {
    color: inherit;
    font-weight: bold;
    text-decoration: underline;
  }
`

const InnerContainer = styled.div`
  max-width: 900px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & img {
    height: 30px;
    width: 30px;
    object-fit: contain;
    margin-right: 12px;
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
`

const U = styled.a`
  font-weight: bold;
  font-size: 18px;
  text-decoration: underline;
  margin-bottom: 20px;
  margin-top: 20px;
  color: inherit;
`

const P = styled.p`
  margin: 24px 0px;
`

export default OurData
