import styled from 'styled-components'

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;

  p, div {
    text-align: ${props => props.align};
  }

  @media(max-width: 767px) {
    align-items: center;
    max-width: 90vw;
    p {
      text-align: left;
    }
  }
`

const BannerImageContent = styled.img`
  object-fit: contain;
  position: relative;
  max-width: 40vw;
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.25));
  -webkit-filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));

  @media(max-width: 1023px) {
    transform: none !important;
    position: static;
  }

  @media(max-width: 767px) {
    max-width: 400px;
    width: 90vw !important;
    height: auto !important;
  }
`

const BannerImageContainer = styled.div`
  display: flex;
  width: -webkit-fill-available;
  justify-content: center;
`

export const BannerImage = props => {
  return (
    <BannerImageContainer>
      <BannerImageContent {...props} />
    </BannerImageContainer>
  )
}

export const DualText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: ${props => props.align};

  > *:nth-child(1) {
    font-weight: 600;
    font-size: 42px;
  }

  > *:nth-child(2) {
    font-weight: 400;
    font-size: 32px;
  }

  @media(max-width: 767px) {
    > * {
      text-align: center !important;
    }

    > *:nth-child(1) {
      font-size: 30px;
    }
  
    > *:nth-child(2) {
      font-size: 20px;
    } 
  }
`

export const clickable = `
  cursor: pointer;
  transition: box-shadow 0.2s ease-in, transform 0.2s ease-in, filter 0.2s ease-in;
  
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 2px 2px rgba(0,0,0,0.2);
  }

  &:active {
    filter: brightness(0.9);
  }
`

export const Button = styled.a`
  color: var(--navy-color);
  background-color: var(--yellow-color);
  min-width: 135px;
  padding: 6.5px 24px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2), inset 0 1px 2px 0 hsla(0, 0%, 100%, .3);
  text-decoration: none;
  border-radius: 6px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  ${clickable}
`

export const Responsive = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media(max-width: 600px) {
    flex-direction: column;
  }
`

export const CardImage = styled.img`
  width: 280px;
  height: 280px;
  ${props => props.shadow ? `
    filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.2));
    -webkit-filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.2));
  ` : ''}

  @media(max-width: 600px) {
    width: 200px;
    height: 200px;
  }
`
