import React from 'react'
import styled from 'styled-components'

const Banner = props => {
  return (
    <Container {...props}>
      {props.children}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  padding: 30px 0px;
  justify-content: space-between;
  
  @media(max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p:nth-child(2) {
    margin: 40px 0px;
  }
`

export default Banner
